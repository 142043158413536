<template>
    <div class="sd-container">

            <div class="sd-box" v-if="imageSide==='right'">
                <div class="sd-left">
                    <p class="sd-caption">{{ imageCaption }}</p>
                    <p class="sd-description">{{ imageDescription }}</p>
                    <styled-button>SEE MORE</styled-button>
                </div>

                <div class="sd-right">
                    <img class="idle" :src="require('@/assets/prices/styles/' + imageName + '.webp')" :alt="'Image of a ' + imageName + ' styled character'">
                </div>
            </div>

            <div class="sd-box" v-if="imageSide==='left'">
                <div class="sd-left">
                    <img class="idle" :src="require('@/assets/prices/styles/' + imageName + '.webp')" :alt="'Image of a ' + imageName + ' styled character'">
                </div>

                <div class="sd-right">
                     <p class="sd-caption">{{ imageCaption }}</p>
                    <p class="sd-description">{{ imageDescription }}</p>
                    <styled-button>SEE MORE</styled-button>
                </div>
            </div>



    </div>
</template>

<script>




export default {

    props: {
        imageName: String,
        imageCaption: String,
        imageDescription: String,
        imageLink: String,
        imageSide: String
    },

    data() {
        return {

        }
    },

    methods: {

    }
}
</script>


<style scoped>

.sd-box, .sd-left, .sd-right {
    display: flex;
}

.sd-box {
    padding-left: 5%;
    padding-right: 5%;
}

.sd-left, .sd-right {
    width: 50%;
}

.sd-left, .sd-right {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sd-caption {
    font-size: var(--font-caption);
    font-weight: var(--weight-l3);
    color: var(--text-l1);
    margin-bottom: 5%;
}

.sd-description {
    text-align: center;
    font-size: var(--font-text);
    font-weight: var(--weight-l4);
    color: var(--text-l2);
    margin-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
}

img {
    width: 100%;
}

</style>