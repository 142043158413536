<template>
    <div class="md-container">

        <div class="md-caption">
            <p>{{ imageData.caption }}</p>
        </div>

        <div class="md-top">
            <div class="md-image">
                <img :src="require('@/assets/prices/styles/' + imageData.name + '.webp')" :alt="'Image of a ' + imageData.name + ' example'">
            </div>

            <div class="md-text">
                <style-prices
                    :imageData="imageData"
                ></style-prices>
            </div>
        </div>

        <div class="md-bot">
            <div class="md-description">
                <p v-for="(desc, index) in imageData.description" :key="index">
                    • {{ desc }}
                </p>
            </div>
        </div>

    </div>
</template>

<script>
import StylePrices from '@/components/prices/components/StylePrices.vue'



export default {

    components: {
        StylePrices
    },

    props: {
        imageData: Object,
        imageName: String,
        imageCaption: String
    },

    data() {
        return {

        }
    },

    methods: {

    }
}
</script>


<style scoped>

.md-caption, .md-container, .md-top, .md-text, .md-description {
    display: flex;
}

.md-container, .md-text, .md-description {
    flex-direction: column;
}

.md-container {
    padding-left: 5%;
    padding-right: 5%;
}

.md-description {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 5%;
    padding-right: 5%;
}

.md-description p {
    font-size: var(--font-desc);
    color: var(--text-l2);
    font-weight: var(--weight-l4);
}

.md-caption {
    justify-content: center;
    align-items: center;
}

.md-caption p {
    font-size: var(--font-caption);
    color: var(--text-l1);
    font-weight: var(--weight-l3);
}

.md-image, .md-text {
    width: 50%;
}

img {
    width: 100%;
}


</style>