<template>
    <div class="sp-container">
        <div class="sp-text">
            <p class="sp-caption">SKETCH</p>
            <p class="sp-price">{{ imageData.sketch.min }} - {{ imageData.sketch.max }} EUR</p>

            <p class="sp-caption">COLORED</p>
            <p class="sp-price">{{ imageData.colored.min }} - {{ imageData.colored.max }} EUR</p>

            <p class="sp-caption">LINE ART</p>
            <p class="sp-price">{{ imageData.lineart.min }} - {{ imageData.lineart.max }} EUR</p>

            <p class="sp-caption">CELL SHADE</p>
            <p class="sp-price">{{ imageData.cellshade.min }} - {{ imageData.cellshade.max }} EUR</p>
        </div>
       
        <div class="sp-button">
            <styled-button>SEE MORE</styled-button>
        </div>
    </div>
</template>

<script>


export default {

    props: {
        imageData: Object
    },

    data() {
        return {

        }
    },

    methods: {

    }
}
</script>


<style scoped>

.sp-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 100%;
}

.sp-button {
    display: flex;
    justify-content: center;
    align-content: center;
}

p {
    text-align: center;
}

.sp-caption {
    font-size: var(--font-text);
    font-weight: var(--weight-l3);
    color: var(--text-l2);
}

.sp-price {
    margin-bottom: 5%;
    font-size: var(--font-text);
    font-weight: var(--weight-l4);
    color: var(--text-l2);
}

</style>