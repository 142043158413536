<template>
    <div class="st-container">
         <side-image
            :imageName="'sketch'"
            :imageCaption="'SKETCH - 30 EUR'"
            :imageDescription="'Clean outlines, no colors or a touch of monochrome. Simple and expressive, capturing the basics'"
            :imageLink="''"
            :imageSide="'right'"
        ></side-image>

         <side-image
            :imageName="'colored'"
            :imageCaption="'COLORED - 35 EUR'"
            :imageDescription="'Vibrant sketches brought to life with colors. Minimal shades, added sparingly for a touch of depth when it fits the artistic vision'"
            :imageLink="''"
            :imageSide="'left'"
        ></side-image>

        <side-image
            :imageName="'lineart'"
            :imageCaption="'LINE ART - 50 EUR'"
            :imageDescription="'Smooth lines, clean colors, and a step up in shading. More depth without losing the crisp look'"
            :imageLink="''"
            :imageSide="'right'"
        ></side-image>

        <side-image
            :imageName="'cellshade'"
            :imageCaption="'CELL SHADE - 65 EUR'"
            :imageDescription="'Crisp lines, vibrant colors, and dynamic shading. Elevate your art with a stylish touch - distinct and eye-catching'"
            :imageLink="''"
            :imageSide="'left'"
        ></side-image>

        <line-separator :marginType="'medium'"></line-separator>
        
        <middle-image
            :imageData="backgroundData"
        ></middle-image>

        <line-separator :marginType="'medium'"></line-separator>

        <middle-image
            :imageData="altData"
        ></middle-image>

        <line-separator :marginType="'medium'"></line-separator>


    </div>
</template>

<script>
import SideImage from '@/components/prices/components/SideImage.vue';
import MiddleImage from '@/components/prices/components/MiddleImage.vue';

export default {

    components: {
        SideImage, MiddleImage
    },

    data() {
        return {
            backgroundData: {
                name: 'background',
                caption: 'BACKGROUND',
                link: '',
                sketch:    {min: 3,  max: 10},
                colored:   {min: 5,  max: 15},
                lineart:   {min: 10, max: 20},
                cellshade: {min: 15, max: 25},
                description: [
                    'Backgrounds are optional',
                    'Style will match the chosen character style',
                    'Price is determined by the level of detail requested'
                ]
            },

            altData: {
                name: 'background',
                caption: 'ALTS',
                link: '',
                sketch:    {min: 3,  max: 10},
                colored:   {min: 5,  max: 15},
                lineart:   {min: 10, max: 20},
                cellshade: {min: 15, max: 25},
                description: [
                    'Alts are optional',
                    'Alts are alternate versions',
                    'Prices vary - minor changes cost less than major one'
                ]
            },
        }
    },

    methods: {
        
    }
}
</script>


<style scoped>



</style>